import Carousel from "react-bootstrap/Carousel";
import figmaScreenshot from "../assets/img/figmaScreenshot.jpg";
import excelScreenshot from "../assets/img/Screenshot excel.png";

// figma , VBA ,

export const Highlights = () => {
	return (
		<div style={{ textAlign: "center", paddingTop: "20px" ,  height: "400px"}}>
                Worth Noticing:

			<Carousel variant="dark" >
				<Carousel.Item>
					<img
						className=""
						style={{  maxHeight: "400px" , maxWidth: "600px", alignItems: "center" }}
						alt="figma"
						src={figmaScreenshot}
					/>
					<Carousel.Caption>
						<h1>Figma Specialist</h1>
						<p> Design screens and workflows, presenting my vision </p>
					</Carousel.Caption>
				</Carousel.Item>

				<Carousel.Item>
                    <img
						className=""
						style={{ maxHeight: "400px" , maxWidth: "600px", alignItems: "center" }}
						alt="figma"
						src={excelScreenshot}
					/>
					<Carousel.Caption>
						<h1>Prioritization Methods</h1>
						<p> Considering Product Vision, Importance, Deliverability, Short term Focus   </p>
					</Carousel.Caption>
				</Carousel.Item>
                <Carousel.Item>
                    <img
						className=""
						style={{ maxHeight: "400px" , maxWidth: "600px", alignItems: "center" }}
						alt="figma"
						src={excelScreenshot}
					/>
					<Carousel.Caption>
						<h1>Prioritization Methods</h1>
						<p> Considering Product Vision, Importance, Deliverability, Short term Focus   </p>
					</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</div>
	);
};
