import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ProjectCard = ({ title, description, imgUrl , url }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <Link to={url} style={{color: "white"}} >
          <h4>{title}</h4>
          <span>{description}</span>
          </Link>
          
        </div>
      </div>
    </Col>
  )
}
