import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/crypto-back.webp";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(150 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Product Manager", "Blockchain Developer", "Crypto Enthusiast" ];
  const period = 1600;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(150);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility once>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                {/* <span className="tagline">Welcome to my Portfolio</span> */}
                <h2>Hello World!</h2> <h1> I'm Asaf</h1>
                <h2>{`I'm  a`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Product Manager", "Blockchain Developer", "Crypto Enthusiast" ]'><span className="wrap">{text}</span></span></h2>
                  <p>Welcome to my website! </p>
                  <p>Hi, I'm Asaf. I'm a highly motivated individual with a passion for learning and growth. I'm a fast learner and always eager to explore and learn new things.<br/> Currently, I'm working on coding this website to showcase my skills and demonstrate my commitment to continuous improvement and professional development.</p>
                  <p>
                  Overall, I'm driven and ambitious. I believe these qualities will serve me well in any future roles or projects I undertake, and I'm excited to see where my career will take me.            </p>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility once>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <div>
        {/* <h3 className="hashtags" style={{marginTop: 30}}>
          #BTC #web3 #Blockchain #NFTs #Solidity #ReactJS #DEX #Collectibles #Connect 
        </h3> */}
      </div>
    </section>
  )
}
