import React from "react";
import PythonIcon from "../assets/img/python.svg";
import ExcelIcon from "../assets/img/microsoft-excel.svg";
import FigmaIcon from "../assets/img/figma.svg";
import EthereumIcon from "../assets/img/ethereum.svg";
import ReactIcon from "../assets/img/react.svg";
import SQLIcon from "../assets/img/sql-9.svg";
import JiraIcon from "../assets/img/jira.svg";
import colorSharp from "../assets/img/color-sharp.webp"

import Card from "react-bootstrap/Card";
import JSIcon from "../assets/img/javascript.svg";
import Figure from "react-bootstrap/Figure";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Skills = () => {
	const textColor = '#fbfbfb';

	return (
		<section className="skill" id="skills">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="skill-bx wow zoomIn">
							<h2>Skills</h2>
							<p>
								{/* Lorem Ipsum is simply dummy text of the printing and typesetting
								industry.<br></br> Lorem Ipsum has been the industry's standard
								dummy text. */}
							</p>
							<Container>
								<Row>
									<Col>
										<Figure >
											<Figure.Image
												width={48}
												height={48}
												alt="Python"
												src={PythonIcon}
											/>
											<Figure.Caption style={{color:textColor}}>Python</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={JSIcon}
											/>
											<Figure.Caption style={{color:textColor}}>JS</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={ReactIcon}
											/>
											<Figure.Caption style={{color:textColor}}>React</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={EthereumIcon}
											/>
											<Figure.Caption style={{color:textColor}}>Solidity</Figure.Caption>
										</Figure>
									</Col>
								</Row>
								<Row>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={FigmaIcon}
											/>
											<Figure.Caption style={{color:textColor}}>Figma</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={JiraIcon}
											/>
											<Figure.Caption style={{color:textColor}}>Jira</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="171x180"
												src={SQLIcon}
											/>
											<Figure.Caption style={{color:textColor}}>SQL</Figure.Caption>
										</Figure>
									</Col>
									<Col>
										<Figure>
											<Figure.Image
												width={48}
												height={48}
												alt="Excel"
												src={ExcelIcon}
											/>
											<Figure.Caption style={{color:textColor}}>Excel</Figure.Caption>
										</Figure>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</div>
			</div>
			<img className="background-image-left" src={colorSharp} alt="Image" />

		</section>
	);
};
