import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            {/* <img src={logo} alt="Logo" /> */}
            <p>I will be happy to connect -
            <span className="social-icon">
              <a href="https://www.linkedin.com/in/asafkhabie/" target="_blank"><img src={navIcon1} alt="Icon" /></a>
 
            </span>
            </p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
       
            
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
