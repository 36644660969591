const  timelineElements = [
    {
        id: 9,
        title: 'Product Manager',
        location: 'Bloxtax',
        description: '' , 
        buttonText: 'Check it out',
        date: '2022-Present' , 
        icon: 'work'

    },
    {
        id: 8,
        title: 'Product Management Course',
        location: 'Product Expert Academy',
        description: '' , 
        buttonText: 'Check it out',
        date: '2022' , 
        icon: ''

    },
    {
        id: 7,
        title: 'Build a Blockchain & Cryptocurrency',
        location: 'Udemy Online Course',
        description: '' , 
        buttonText: 'Check it out',
        date: '2021' , 
        icon: ''

    },
    {
        id: 6,
        title: 'Team Leader - Account Manager',
        location: 'Bloxtax',
        description: '' , 
        buttonText: 'Check it out',
        date: '2021-2022' , 
        icon: 'work'

    },
    {
        id: 5,
        title: 'Ethereum and Solidity',
        location: 'Udemy Online Course',
        description: '' , 
        buttonText: 'Check it out',
        date: '2020' , 
        icon: ''

    },
    {
        id: 4,
        title: '"JUMP" Accelerator',
        location: 'Starter Program - Tech7',
        description: '' , 
        buttonText: 'Check it out',
        date: '2019' , 
        icon: ''

    },
    {
        id: 3,
        title: 'Business Consultant & Data Analyst',
        location: 'Internship - 7i`s',
        description: '' , 
        buttonText: 'Check it out',
        date: '2018-2019' , 
        icon: 'work'
        

    },
    {
        id: 2,
        title: 'B.Sc. at Industrial and Management Engineering',
        location: 'Ben Gurion University',
        description: '' , 
        buttonText: 'Check it out',
        date: '2017 - 2020' , 
        icon: ''

    },
    {
        id: 1,
        title: 'Amazon Store Owner',
        location: 'Independent',
        description: '' , 
        buttonText: 'Check it out',
        date: '2016 - 2020' , 
        icon: 'work'

    }
];

export default timelineElements;