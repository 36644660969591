import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { Skills } from "./Skills_copy";
import { Footer } from "./Footer";
import { Projects } from "./Projects";
import Timeline from "./Timeline";
import { Highlights } from "./highlights";
import Soulbound from './Soulbound/components/Soulbound-app'
import { BrowserRouter, Route, Switch } from "react-router-dom";

export const Main = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<NavBar />
						<Banner />
						<Skills />
						<Timeline />
						<Projects />
						<Footer />
					</Route>

					<Route path='/soulbound' >
						<Soulbound />
					</Route>
					
					<Route path='/highlights' >
						<Highlights />
					</Route>
				</Switch>
			</BrowserRouter>

			{/* <Contact /> */}
		</div>
	);
};
