import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import mainScreen from "../assets/img/mainScreen.webp";
import soon from "../assets/img/comingSoon.webp";
import sbtPic from "../assets/img/SBT_screen.webp";

import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
	const projects = [
		{
			title: "This Website :)",
			description: "React JS",
			imgUrl: mainScreen,
		},
		{
			title: "NFT - Soulbound Token",
			description: "React JS, Solidity",
			imgUrl: sbtPic,
			url: "/Soulbound",
		},
		{
			title: "Coming Soon",
			description: "",
			imgUrl: soon,
		},
	];

	return (
		<section className="projects" id="projects">
			<Container>
				<Row>
					<Col size={12}>
						<TrackVisibility once>
							{({ isVisible }) => (
								<div
									style={{ textAlign: "center" }}
									className={
										isVisible ? "animate__animated animate__fadeIn" : ""
									}
								>
									<h1 style={{ fontSize: 46 }}>Projects</h1>
									<p style={{ fontSize: 18 }}>
										I have a keen interest in blockchain technology.
										<br /> I believe in the power of technology to solve complex
										problems and create meaningful change.
									</p>
									<Tab.Container id="projects-tabs" defaultActiveKey="first">
										{/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
										<Tab.Content id="slideInUp">
											<Tab.Pane eventKey="first">
												<Row>
													{projects.map((project, index) => {
														return <ProjectCard key={index} {...project} />;
													})}
												</Row>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							)}
						</TrackVisibility>
					</Col>
				</Row>
			</Container>
			<img className="background-image-right" src={colorSharp2}></img>
		</section>
	);
};
