import "../App.css";
import timelineElements from "./timelineElements";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { ReactComponent as WorkIcon } from "../assets/img/job.svg";
import { ReactComponent as SchoolIcon } from "../assets/img/academic.svg";


const Timeline = () => {
	let workIconStyle = { background: "#06D6A0" };
	let schoolIconStyle = { background: "#f9c74f" };
	let containerStyle = { background: "#f9c74f" };
	return (
		<section className="experience" id="experience">
			<div>

				<h1 className="timeline-title"> My Experience</h1>
				<VerticalTimeline className="timeline">
					{timelineElements.map((element) => {
						let isWorkIcon = element.icon === "work";
						let showButton =
							element.buttonText !== undefined &&
							element.buttonText !== null &&
							element.buttonText !== "";
						return (
							<VerticalTimelineElement
								key={element.id}
								date={element.date}
								dateClassName="timeline-date"
								iconStyle={isWorkIcon ? workIconStyle : schoolIconStyle}
								icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
								className="timeline-element"
								contentStyle={{ background: "#45263A" , color:'white',borderRadius: '1.2em' , boxShadow:'0 0px 0 #ddd' , backgroundImage: 'linear-gradient(to bottom right, #45263A, #3B2D7D)' }} // here we change color of background
								position={isWorkIcon ? "left" : "right"}s
							>
								<h3 className="vertical-timeline-element-title">
									{element.title}
								</h3>
								<h5 className="vertical-timeline-element-subtitle">
									{" "}
									{element.location}{" "}
								</h5>
								<p id="description">{element.description}</p>
								{/* <p> {element.date}</p> */}
							</VerticalTimelineElement>
						);
					})}
				</VerticalTimeline>
			</div>
			<br/><br/>
		</section>
	);
};

export default Timeline;
