import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Main } from "./components/Main";
// import {Soulbound} from './components/Soulbound'

// import {Route , Router } from "react-router-dom";

function App() {
	return (
		// <Router>
		// 		<Route path="/" element={<Main />} />
    //     <Route path="/nft" element={<Soulbound />} />
		// </Router>
    <Main />
	);
}

export default App;
