import "semantic-ui-css/semantic.min.css";
import "./App.css";
import React, { useState, useEffect } from "react";
import backgroundImage from "../../../assets/img/kan-bg.webp";
import { Container, Button, Grid, Menu, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import greetingCard from "./greetingCardMatic";
import Web3 from "web3";

const Soulbound = () => {
	const [userAddress, setUserAddress] = useState("");
	const [correctNet, setCorrectNet] = useState(false);
	const [gotEth, setGotEth] = useState(false);
	const [txhash, setTxhash] = useState("");
	const [faucetErrorMessage, setFaucetErrorMessage] = useState("");
	const [messageUser, setMessageUser] = useState("");
	const [isConnected, setIsConnected] = useState(false);
	const [hasNFT, setHasNFT] = useState(false);
	const [pending, setPending] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [refresh, setRefresh] = useState(false);
	const web3 = new Web3(window.ethereum);

	const styles = {
		backgroundImage: `url(${backgroundImage})`,
		backgroundColor: "black",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		minHeight: "100vh",
	};

	const checkNetId = async () => {
		const net = await web3.eth.net.getId();
		if ( net === 80001) {
			setErrorMessage("");
			setCorrectNet(true);
		} else {
			setCorrectNet(false);
			setErrorMessage("Please switch to ETH Goerli Testnet");
		}
		setRefresh(false)
	};

	useEffect(() => {
		checkNetId();
	});

	useEffect(() => {
		checkNetId();
	} , refresh);

	const sendFromFaucet = async () => {
		if (correctNet) {
			sendETH();
		} else {
		}
	};

	const sendETH = async () => {
		console.log(userAddress);
		const transaction = {
			to: userAddress,
			from: "0x47bfb8E0794286E3B1aB947956C2eBCa642f0BC6",
			value: 10000000000000000, //10000000000000000 is 0.01 ETH
			gas: 30000,
			data: "",
		};
		let signedTx;
		try {
			signedTx = await web3.eth.accounts.signTransaction(
				transaction,
				"a93c3c72355c0349aa0025300a67ed8007eef04a0d6d5fcdbd8b8530547eff9e"
			);
		} catch (err) {
			console.log("Caught error signedTx");
			console.log(err);
		}
		if (signedTx) {
			try {
				web3.eth.sendSignedTransaction(signedTx.rawTransaction, (err, hash) => {
					if (!err) {
						console.log("Transaction has been mined - ", hash);
						setTxhash("https://mumbai.polygonscan.com/tx/" + hash);
						setGotEth(true);
						setFaucetErrorMessage("");
					} else {
						console.log("Something went wrong - ", err);
						if (err.code == -32000) {
							console.log("this is correct");
							setFaucetErrorMessage(
								"The network is too congested, please try again later"
							);
						} else {
							setFaucetErrorMessage(
								"Something went wrong, please try again later"
							);
						}
					}
				});
			} catch (err) {
				console.log("Caught error sendSignedTransaction");
				console.log(err);
			}
		}
	};

	const checkOwner = async () => {
		try {
			const hasNFT = await greetingCard.methods.issuedCards(userAddress).call();
			setHasNFT(hasNFT);
		} catch (err) {
			console.log(err);
		}
	};

	const onBurnClick = async () => {
		if (hasNFT) {
			setPending(true);

			const account = await web3.eth.getAccounts();
			setMessageUser("Sending Burn Request");
			try {
				const burnRequest = await greetingCard.methods.burnMyToken().send({
					from: account[0],
				});
				console.log(burnRequest);
				setMessageUser(
					`NFT ID ${burnRequest.events.Transfer.returnValues.tokenId} has been burned`
				);
				setHasNFT(false);
			} catch (err) {
				console.log(err);
				setMessageUser("Burning Failed :(");
			}
		}
		setPending(false);
		checkOwner();
	};

	const onClickClaim = async () => { 
		if (correctNet) {
			onClickClaim2()
		}
		else{
			setErrorMessage("Switch to Goerli Testnet")
		}
	}

	const onClickClaim2 = async () => {
		const account = await web3.eth.getAccounts();

		// setMessageUser("sending NFT");
		setPending(true);
		try {
			const nftId = await greetingCard.methods
				.claimGreetingCard(
					"ipfs://bafkreid4ppdqlzjpxoovcwmseq4e243telpfoypj4dc24kgn5ii6gp5wxy"
				)
				.send({
					from: account[0],
				});
			console.log(nftId);
			setMessageUser(
				`NFT ID ${nftId.events.Transfer.returnValues.tokenId} issued!`
			);
			// setShowLink(true);
		} catch (err) {
			console.log("error is ", err);
			setMessageUser("Sending Failed :(");
		}
		setPending(false);
		checkOwner();
	};

	const onClickConnect = async () => {
		console.log(correctNet)
		// console.log("Working");
		if (window.ethereum) {
			// console.log("MM detected");
			try {
				const accounts = await window.ethereum.request({
					method: "eth_requestAccounts",
				});
				setUserAddress(accounts[0]);
				console.log(accounts[0]);
				setIsConnected(true);
				checkOwner();
			} catch (err) {
				console.log(err);
				setUserAddress("");
			}
		} else {
			console.log("MM not detected");
		}
	};

	const connectButtonText = () => {
		const text =
			userAddress === ""
				? "Connect with Metamask"
				: "Connected: " +
				  userAddress.substring(0, 3) +
				  "..." +
				  userAddress.substring(39, 42);

		checkOwner();
		return text;
	};

	const faucetButton = () => {
		return (
			<div>
				<Button
					primary
					onClick={sendFromFaucet}
					disabled={gotEth || !isConnected || !correctNet}
				>
					Get free Matic
				</Button>
				<br />
				{/* <Button primary onClick={mintToAddress} disabled={gotEth}>
					Get nft
				</Button> */}
				<br />
				<div hidden={!gotEth}>It may take up to 30 seconds </div>
				<a href={txhash} hidden={!gotEth} target="_blank">
					Watch it on polygonscan
				</a>
			</div>
		);
	};

	const checkMetamask = () => {
		if (window.ethereum) {
			if (correctNet){
				return (
					<Button color="blue" onClick={onClickConnect}>
						{connectButtonText()}
					</Button>
				);
			} else {
				return (
				<Button color="blue" onClick={switchToTestnet}>
				Switch to Mumbai (Matic Testnet)
			</Button>
				)
			}
		
		} else {
			return (
				<span className="">
					<Button color="blue" onClick={onClickConnect}>
						<a
							style={{ color: "white" }}
							href="https://metamask.io/download/"
							target="_blank"
						>
							Install Metamask
						</a>
					</Button>
				</span>
			);
		}
	};

	const logUserAddress = () => {
		console.log(userAddress);
		console.log(isConnected);
	};

	const switchToTestnet = async () => {
		if (window.ethereum) {
			try {
				// check if the chain to connect to is installed
				const test = await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{ chainId: "0x80001" }], // chainId must be in hexadecimal numbers
				});
				console.log("test is " + test);
			} catch (error) {
				// This error code indicates that the chain has not been added to MetaMask
				// if it is not, then install it into the user MetaMask
				if (error.code === 4902) {
					try {
						await window.ethereum.request({
							method: "wallet_addEthereumChain",
							params: [
								{
									chainName: 'Mumbai',
									chainId: '0x13881',
									rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
									nativeCurrency: {
										"name": "MATIC",
										"symbol": "MATIC",
										"decimals": 18
									  }
								},
							],
						});
					} catch (addError) {
						console.log("error adding the network")
						console.error(addError);
					}
				}
				console.error(error);
			}
		} else {
			// if no window.ethereum then MetaMask is not installed
			alert(
				"MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
			);
		}
		setRefresh(true);
	};

	return (
		<div>
			<Menu borderless fixed="top" inverted>
				<Container text>
					<Menu.Menu position="left">
						<Menu.Item header position="left">
							<Link to="/">Back</Link>
						</Menu.Item>
					</Menu.Menu>
					<Menu.Menu position="right">
						<span style={{ textAlign: "right", marginTop: "7px" }}>
							{checkMetamask()}
						</span>
					</Menu.Menu>
				</Container>
			</Menu>
			<Segment stacked style={styles}>
				<Grid
					columns={2}
					s
					textAlign="center"
					style={{ height: "100vh" }}
					verticalAlign="middle"
				>
					<Grid.Row></Grid.Row>
					<Grid.Row>
						<Grid.Column style={{ maxWidth: 500 }}>
							<div >
								<p
									style={{
										textAlign: "justify",
										lineHeight: 1.5,
										fontSize: "1.15rem",
									}}
								>
									<h1 style={{ fontSize: 50 }}>
										<u> Soulbound NFTs</u>
									</h1>
									<h2>What is a Soulbound token (SBT)?</h2>
									<span>
										SBT is a type of non-fungible token (NFT) that is designed
										to be permanently bound to a specific account (or “soul”).
										<br />
										It cannot be transferred or traded.
									</span>
									<br />
									<h2>Why? </h2>
									<span>
										Relaying on the fundamental advantages of the blockchain:
										trustless, decentralised, public - SBTs can be used for
										representing verifiable credential like:
										<ul>
											<li>Identity - driver licence or national ID</li>
											<li>Achievements based items (in gaming)</li>
											<li>
												Official certificates - College degree, SAT results
											</li>
											<li>Credit Scoring </li>
											<li>POAP - Proof of Attendance Protocol</li>
										</ul>
									</span>
									<h2>So What? </h2>
									<span>
										I belive that NFTs have a much greater potential than being traded to make profits.<br />
										In the words of Vitalik Buterin (founder of Ethereum):
										<br />
										<section style={{ fontStyle: "italic" }}>
											"Making more items in the crypto space "soulbound" can be
											one path toward an alternative, where NFTs can represent
											much more of who you are and not just what you can
											afford."
										</section>
									</span>
								</p>
							</div>
						</Grid.Column>
						<Grid.Column style={{ maxWidth: 400 }}>
							<div className="instructions">
								<h1>Try It Yourself</h1> <br />
								<p >
									I launched a POAP NFT on the Polygon testnet - Mumbai. 
									This POAP NFT can only be claimed once. It can't be transferred or traded - it can only be minted or burned.
									
									
								</p>
								<h2>Step 1 </h2>
								Switch to Mumbai Testnet
								<h2>Step 2 </h2>
								Get free Matic for gas
								{/* <Faucet userAddress={userAddress} /> */}
								{faucetButton()}
								<span style={{ color: "red" }}>{faucetErrorMessage}</span>
								<h2>Step 3</h2>
								<Button
									primary
									onClick={onClickClaim}
									disabled={
										hasNFT || userAddress == "" || !isConnected || !correctNet
									}
									loading={pending}
								>
									Claim NFT
								</Button>
								<br />
								<br />
								<Button
									onClick={onBurnClick}
									disabled={(!hasNFT && !isConnected) || !correctNet}
									loading={pending}
									hidden={!hasNFT}
								>
									Burn NFT
								</Button>
								{}
								<br />
								<br />
								<br />
								<div className="middle">
									{messageUser + "     "}<br />
									<a
										href={`https://testnets.opensea.io/${userAddress}`}
										hidden={!hasNFT}
										target="_blank"
									>
										Watch Your NFT on Opensea Testnet!
									</a>
									
								</div>
								{errorMessage}
							</div>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row></Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default Soulbound;
