import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = () => {
	const [activeLink, setActiveLink] = useState("home");
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const onUpdateActiveLink = (value) => {
		setActiveLink(value);
	};

	return (
		<Router>
			<Navbar expand="md" className={scrolled ? "scrolled" : ""}>
				<Container>
					<Navbar.Brand href="/">
						{/* <img src={logo} alt="Logo" /> */}
						<span style={{color: "white"}}>
						Asaf Khabie
						</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="navbar-toggler-icon"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Nav.Link
								href="#home"
								className={
									activeLink === "home" ? "active navbar-link" : "navbar-link"
								}
								onClick={() => onUpdateActiveLink("home")}
							>
								About Me
							</Nav.Link>
							<Nav.Link
								href="#skills"
								className={
									activeLink === "skills" ? "active navbar-link" : "navbar-link"
								}
								onClick={() => onUpdateActiveLink("skills")}
							>
								Skills
							</Nav.Link>
							<Nav.Link
								href="#experience"
								className={
									activeLink === "experience"
										? "active navbar-link"
										: "navbar-link"
								}
								onClick={() => onUpdateActiveLink("experience")}
							>
								Experience
							</Nav.Link>
							<Nav.Link
								href="#projects"
								className={
									activeLink === "projects"
										? "active navbar-link"
										: "navbar-link"
								}
								onClick={() => onUpdateActiveLink("projects")}
							>
								Projects
							</Nav.Link>
						
						</Nav>
						<span className="navbar-text">
							<div className="social-icon">
								{/* <a
									href="https://www.linkedin.com/in/asafkhabie/"
									target="_blank"
								>
									<img src={navIcon1} alt="" />
								</a>
								<a href="https://www.facebook.com/asafkh6" target="_blank">
									<img src={navIcon2} alt="" />
								</a>
								<a href="https://github.com/asafkh6" target="_blank">
									<img src={gitIcon2} alt="" />
								</a> */}
							</div>
							
									<span >

									<a
									href="https://www.linkedin.com/in/asafkhabie/"
									target="_blank"
									style={{color: "whitesmoke"}}
								>
									Let’s Connect !
								</a>

									</span>
							
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Router>
	);
};
